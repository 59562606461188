/* eslint-disable no-unused-vars */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { sortBy } from 'lodash';
import {
  sendEmailSanitaryUser,
  sendEmailSecurityUser,
  sendEmailSanitaryAdmin,
  sendEmailSecurityAdmin,
  sendRecapReports,
} from '../../services/mail';
import { calculeIndicateur } from '../../services/indicator';
import { generateInput } from '../../services/inputs';
import { getRewardContract } from '../../services/contracts';
import { getEstablishmentsList, getGroupsList } from '../../services/structures';
import Layout from '../../components/template/Layout';
import cn from '../../utils/cn';
import Card from '../../components/atoms/Card/Card';
import useAppContext from '../../store/useAppContext';
import styles from './Cron.module.css';
import Select from '../../components/atoms/Select/Select';
import Button from '../../components/atoms/Button/Button';

function Cron() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [context] = useAppContext();
  const [establishement, setEstablishement] = React.useState([]);
  const [group, setGroup] = React.useState([]);

  const navigateDashboards = () => {
    const etablissement = context?.choiceEstablishment?.name;
    if (context?.choiceBooklet === 1 && !context?.user?.role?.includes('ADMIN')) {
      navigate(`/sanitaire/${etablissement}/dashboards`);
    } else if (context.choiceBooklet === 2 && !context?.user?.role?.includes('ADMIN')) {
      navigate(`/securite/${etablissement}/dashboards`);
    } else if (context.choiceBooklet === 3 && !context?.user?.role?.includes('ADMIN')) {
      navigate(`/vehicule/${etablissement}/dashboards`);
    } else if (context?.user?.role?.includes('ADMIN')) {
      navigate('/dashboard');
    } else {
      navigate('/choix-carnet');
    }
  };

  const getEstablishmentsListQuery = useQuery(['establishment'], () => getEstablishmentsList());

  const getGroupsListQuery = useQuery(['group'], () => getGroupsList());
  const mapForSelect = (arrayToMap) => (arrayToMap?.length
    ? sortBy(arrayToMap, ['establishment']).map((item) => ({
      ...item,
      label: item.name,
      value: item.id,
    }))
    : []);

  const SaisieCronQuery = useMutation(generateInput, {
    onSuccess: () => {
      toast.success(t('cron.successSaisie'));
    },
    onError: () => {
      toast.error(t('cron.errorSaisie'));
    },
  });

  const IndicateurSaisie = useMutation(calculeIndicateur, {
    onSuccess: () => {
      toast.success(t('cron.successIndicateur'));
    },
    onError: () => {
      toast.error(t('cron.errorIndicateur'));
    },
  });

  const sendUserSanitaryEmail = useMutation(sendEmailSanitaryUser, {
    onSuccess: () => {
      toast.success(t('cron.successEmail'));
    },
    onError: () => {
      toast.error(t('cron.errorEmail'));
    },
  });

  const sendUserSecurityEmail = useMutation(sendEmailSecurityUser, {
    onSuccess: () => {
      toast.success(t('cron.successEmail'));
    },
    onError: () => {
      toast.error(t('cron.errorEmail'));
    },
  });

  const sendAdminSanitary = useMutation(sendEmailSanitaryAdmin, {
    onSuccess: () => {
      toast.success(t('cron.successEmail'));
    },
    onError: () => {
      toast.error(t('cron.errorEmail'));
    },
  });

  const sendAdminSecurity = useMutation(sendEmailSecurityAdmin, {
    onSuccess: () => {
      toast.success(t('cron.successEmail'));
    },
    onError: () => {
      toast.error(t('cron.errorEmail'));
    },
  });

  const sendRecapEmail = useMutation(sendRecapReports, {
    onSuccess: () => {
      toast.success(t('cron.successEmail'));
    },
    onError: () => {
      toast.error(t('cron.errorEmail'));
    },
  });

  const ContractSaisie = useMutation(getRewardContract, {
    onSuccess: () => {
      toast.success(t('cron.successContract'));
    },
    onError: () => {
      toast.error(t('cron.errorContract'));
    },
  });

  const onChangeEstablishement = (e) => {
    setEstablishement(e.value);
  };

  const onChangeGroup = (e) => {
    setGroup(e.value);
  };

  const sendMail = (mailNumber) => {
    if (mailNumber === 1) {
      sendUserSanitaryEmail.mutate(establishement);
    } else if (mailNumber === 2) {
      sendUserSecurityEmail.mutate(establishement);
    } else if (mailNumber === 3) {
      sendAdminSanitary.mutate(group);
    } else if (mailNumber === 4) {
      sendAdminSecurity.mutate(group);
    } else if (mailNumber === 5) {
      sendRecapEmail.mutate(establishement);
    }
  };

  return (
    <Layout
      title="Cron"
      description="Cron"
      keywords="Cron"
    >
      <button type="button" className="link" onClick={() => navigateDashboards()}>
        <FontAwesomeIcon icon={faChevronLeft} />
        <span>{t('global.dashboard')}</span>
      </button>
      <br />
      <br />
      <Card>
        <div className={styles.title}>
          <h1 className="title no-margin">{t('cron.email')}</h1>
        </div>
      </Card>
      <div className={styles.card}>
        <div className={styles.emailsUser}>
          <Select
            label={t('cron.etablissement')}
            name="etablissement"
            options={
              mapForSelect(getEstablishmentsListQuery?.data?.data?.establishments)
            }
            className={styles.establishement}
            onChange={(e) => onChangeEstablishement(e)}
            isLoading={getEstablishmentsListQuery.isLoading}
          />
          <Button
            label={t('cron.utilisateur_sanitaire')}
            onClick={() => sendMail(1)}
            className={styles.utilisateur_sanitaire}
            isLoading={sendUserSanitaryEmail.isLoading}
          />
          <Button
            label={t('cron.utilisateur_securite')}
            onClick={() => sendMail(2)}
            className={styles.utilisateur_securite}
            isLoading={sendUserSecurityEmail.isLoading}
          />
        </div>
        <div className={styles.admin}>
          <Select
            label={t('cron.group')}
            name="group"
            options={
              mapForSelect(getGroupsListQuery?.data?.data?.groups)
            }
            className={styles.group}
            onChange={(e) => onChangeGroup(e)}
            isLoading={getGroupsListQuery.isLoading}
          />
          <Button
            label={t('cron.admin_sanitaire')}
            onClick={() => sendMail(3)}
            className={styles.admin_sanitaire}
            isLoading={sendAdminSanitary.isLoading}
          />
          <Button
            label={t('cron.admin_securite')}
            onClick={() => sendMail(4)}
            className={styles.admin_securite}
            isLoading={sendAdminSecurity.isLoading}
          />
        </div>
        <Button
          label={t('cron.recap_email')}
          onClick={() => sendMail(5)}
          className={styles.recap_email}
          isLoading={sendRecapEmail.isLoading}
        />
      </div>
      <br />
      <div className={styles.indicateur}>
        <Card>
          <div className={cn([styles.topTitle, styles.alignedCenter])}>
            <h1 className="title no-margin">{t('cron.indicateur')}</h1>
          </div>
        </Card>
        <br />
        <Button
          label={t('cron.indicateur')}
          onClick={() => IndicateurSaisie.mutate()}
          className={styles.indicateur}
          isLoading={IndicateurSaisie.isLoading}
        />
      </div>
      <br />
      <div className={styles.saisie}>
        <Card>
          <div className={cn([styles.titleSaisie, styles.alignedCenter])}>
            <h1 className="title no-margin">{t('cron.saisie')}</h1>
          </div>
        </Card>
        <br />
        <Button
          label={t('cron.saisie_refresh')}
          onClick={() => SaisieCronQuery.mutate()}
          className={styles.saisie_refresh}
          isLoading={SaisieCronQuery.isLoading}
        />
        <br />
      </div>
      <div className={styles.indicateur}>
        <Card>
          <div className={cn([styles.topTitle, styles.alignedCenter])}>
            <h1 className="title no-margin">{t('cron.contract')}</h1>
          </div>
        </Card>
        <br />
        <Button
          label={t('cron.contract')}
          onClick={() => ContractSaisie.mutate()}
          className={styles.indicateur}
          isLoading={ContractSaisie.isLoading}
        />
      </div>
      <br />
      <button type="button" className="link" onClick={() => navigateDashboards()}>
        <FontAwesomeIcon icon={faChevronLeft} />
        <span>{t('global.dashboard')}</span>
      </button>
    </Layout>
  );
}

export default Cron;
