/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Select from 'react-select';
import Modal from 'react-modal';
import ReactTooltip from 'react-tooltip';
import { debounce } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit, faTrashCan, faChevronLeft, faChevronRight, faAddressCard, faMessage, faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';

// Components
import Layout from '../../components/template/Layout';
import Permissions from '../../components/molecules/Permissions/Permissions';
import Table from '../../components/molecules/Table/Table';
import Pagination from '../../components/molecules/Pagination';
import Search from '../../components/molecules/Search/Search';
import Toggle from '../../components/atoms/Toggle/Toggle';

// Hooks
import useTableFiltersPagination from '../../hooks/useTableFiltersPagination';
import useAppContext from '../../store/useAppContext';

// Services
import { getInputsHistory, deleteInput } from '../../services/inputs';
import { getUsersList } from '../../services/users';
import { getBuildingsList } from '../../services/structures';
import { downloadFileCsv } from '../../utils/downloadFile';

// Styles
import styles from './HistoricInputs.module.css';

// Utils
import { formatDateToUser } from '../../utils/dates';

const customStyles = {
  control: (provided) => ({
    ...provided,
    minWidth: '14.375rem',
    padding: '2px',
    border: '2px solid #dcdee8',
    borderRadius: '1.875rem',
  }),
};

// TO DO : REFACTO TO MAKE IT GENERIC TO ALL OTHERS MODALS
const customModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    maxWidth: '80vw',
    minWidth: '1024px',
    transform: 'translate(-50%, -50%)',
    padding: '2rem',
  },
};

function HistoricInputs() {
  const [openModal, setOpenModal] = useState(false);
  const [inputToDelete, setInputToDelete] = useState({
    id: null,
  });
  const [search, setSearch] = useState('');
  const [building, setBuilding] = useState(null);
  const [completedSet, setCompletedSet] = useState(undefined);
  const [checkedd, setChecked] = useState(false);

  const { t } = useTranslation();
  const { page, onPageChange } = useTableFiltersPagination({ initialFilters: {} });
  const [context] = useAppContext();
  const [dowloadInfo, setDowloadInfo] = useState([]);
  const [telechargement, setTelechargement] = useState(false);
  // const [sort, setSort] = useState('asc');

  // API Calls
  const getHistoricInputsListQuery = useQuery(['historicInputs', page, search, context.choiceBooklet, completedSet, building], () => getInputsHistory({
    page: telechargement ? 0 : page,
    search,
    completed: completedSet,
    bookletId: context.choiceBooklet,
    establishmentId: context.choiceEstablishment?.id,
    buildingIds: building,
  }), {
    onSuccess: (data) => {
      if (telechargement) {
        setDowloadInfo(data.data.inputs);
      }
    },
  });

  const getBuildingsListQuery = useQuery(['buildings'], () => getBuildingsList({
    establishmentId: context.choiceEstablishment?.id,
    bookletId: context.choiceBooklet,
  }));
  const getUsersListQuery = useQuery(['usersList'], () => getUsersList({
    structureId: context.choiceEstablishment?.id,
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
  }));

  const buildingsMapped = getBuildingsListQuery?.data?.data?.buildings?.map((buildingIte) => ({
    label: buildingIte?.name,
    value: buildingIte?.id,
  }));

  const handleModalToDelete = (selectedEquipmentToDelete) => {
    setOpenModal(true);
    setInputToDelete(selectedEquipmentToDelete);
  };

  const deleteInputMutation = useMutation(deleteInput, {
    onSuccess:
      () => {
        getHistoricInputsListQuery.refetch();
      },
  });

  const handleDelete = () => {
    deleteInputMutation.mutate(inputToDelete?.id);
    setOpenModal(false);
    toast.success(t('inputs_historic.deleted'));
  };

  const handleBuilding = (buildings) => {
    const buildingIds = buildings.map((buildingIte) => buildingIte.value);
    setBuilding(buildingIds);
  };

  const handleSearch = debounce((e) => {
    onPageChange(1);
    setSearch(e.target.value.toLowerCase());
  }, 500);

  const findUser = (userId) => {
    const a = getUsersListQuery?.data?.data?.users
      ?.map((user) => ({ ...user, name: `${user.firstName} ${user.lastName}` }))
      ?.find((user) => user?.id === userId);
    return a;
  };

  const columns = useMemo(
    () => [
      {
        Header: `${t('inputs_historic.date')}`,
        accessor: 'inputDate',
        Cell: ({ row: { original: { inputDate, inputScheduleDate, completed } } }) => (
          inputDate !== null ? (
            <div className={completed === false ? styles.notInputed : ''}>
              {formatDateToUser(inputDate)}
            </div>
          ) : (
            <div className={completed === false ? styles.notInputed : ''}>
              {formatDateToUser(inputScheduleDate)}
            </div>
          )
        ),
      },
      {
        Header: t('interventions.user'),
        accessor: 'userInChargeId',
        Cell: ({ row: { original: { id, completeUser } } }) => (
          <div className={styles.user}>
            <FontAwesomeIcon data-tip data-for={`userColResult${id}`} icon={faAddressCard} />
            <ReactTooltip id={`userColResult${id}`} place="top" type="info" effect="solid">
              {completeUser !== null ? (
                `${completeUser}`
              ) : (
                '-'
              )}
            </ReactTooltip>
          </div>
        ),
      },
      {
        Header: `${t('inputs_historic.name')}`,
        accessor: 'title',
        Cell: ({ row: { original: { title, completed } } }) => (
          <div className={completed === false ? styles.notInputed : ''}>
            {title}
          </div>
        ),

      },
      {
        Header: `${t('inputs_historic.comment')}`,
        accessor: 'comment',
        Cell: ({
          row: {
            original: {
              alert,
              alertHelp,
              alertComment,
              inputType,
              historyComment,
              completed,
              numberOfCompleted,
              totalToComplete,
            },
          },
        }) => (
          <div
            className={`${completed === false ? styles.notInputed : ''} ${alert === true ? styles.alertStyle : ''}`}
          >
            { inputType === 'eclairage_securite' ? (
              <>
                { numberOfCompleted ? `${numberOfCompleted}/${totalToComplete} OK` : null }
                <div>{' '}</div>
              </>
            ) : null}
            { historyComment && historyComment.substring(0, 50) + (historyComment.length > 50 ? '...' : ' ') }
            { alert === true ? (
              <>
                { alertComment }
                <FontAwesomeIcon className={styles.right} icon={faMessage} title="Message d'alerte" />
              </>
            ) : (
              ''
            )}
            { alertHelp === true ? (
              <FontAwesomeIcon className={styles.right} icon={faQuestionCircle} title="Besoin d'assistance" />
            ) : (
              ''
            )}
          </div>
        ),
      },
      {
        Header: ' ',
        Cell: ({ row: { original: { id } } }) => (
          <div className="actions">
            {Permissions({ permission: 'INPUTHISTORY_READ' }) !== undefined
              ? (
                <Link
                  className="action"
                  to={`/saisies/historique/edit/${id}`}
                  title={t('inputs_historic.edit')}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </Link>
              ) : null }
            {Permissions({ permission: 'INPUTHISTORY_DELETE' }) !== undefined
              ? (
                <button
                  type="button"
                  className="action suppr"
                  title={t('inputs_historic.suppr')}
                  onClick={() => handleModalToDelete({ id })}
                >
                  <FontAwesomeIcon icon={faTrashCan} />
                </button>
              ) : null }
          </div>
        ),
      },
    ],
    [],
  );
  const findBuilding = (buildingId) => {
    const a = getBuildingsListQuery?.data?.data?.buildings?.find((res) => res?.id === buildingId);
    return a;
  };
  const handleChange = () => {
    onPageChange(1);
    if (completedSet === undefined) {
      setCompletedSet(false);
      setChecked(true);
    }
    if (completedSet === false) {
      setCompletedSet(undefined);
      setChecked(false);
    }
  };
  const hearder = [
    { key: 'id', label: t('inputs_historic.id') },
    {
      key: 'inputDate',
      label: t('inputs_historic.initial_control_date'),
      cell: (row) => new Date(row.original.inputDate).toLocaleDateString(),
    },
    {
      key: 'Building',
      label: t('inputs_historic.buildings'),
      cell: (row) => findBuilding(row.original.buildingId)?.name,
    },
    { key: 'title', label: t('inputs_historic.name') },
    {
      key: 'completed',
      label: t('inputs_historic.state'),
      cell: (row) => (row.original.completed ? t('inputs_historic.inputed') : t('inputs_historic.not_inputed')
      ),
    },
    { key: 'comment', label: t('inputs_historic.comment') },
    {
      key: 'userInChargeId',
      label: t('interventions.user'),
      cell: (row) => findUser(row.original.userInChargeId)?.name ?? '-',
    },
  ];
  const handleDowload = () => {
    setTelechargement(true);
    getHistoricInputsListQuery.refetch();
    if (dowloadInfo.length !== 0) {
      downloadFileCsv(dowloadInfo, hearder, `${t('inputs_historic.title_page')}`);
      setTelechargement(false);
    }
  };

  return (
    <Layout
      title={t('inputs_historic.title_page')}
      layout="table"
      queryError={
        getHistoricInputsListQuery?.error
        || getUsersListQuery?.error
        || getBuildingsListQuery?.error
        || deleteInputMutation?.error
      }
      isLoading={
        getHistoricInputsListQuery.isLoading
        || getUsersListQuery.isLoading
        || getBuildingsListQuery.isLoading
        || deleteInputMutation.isLoading
      }
    >
      <header className="header">
        <div className="row mb-20">
          <Link to="/saisies" className="link">
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('inputs_historic.back_to_inputs')}</span>
          </Link>
        </div>
        <div className="row mb-20">
          <h1 className="title">{t('inputs_historic.title_page')}</h1>
        </div>
        <div className={styles.search}>
          <Search className="mb-20" onChange={handleSearch} />
        </div>
        <div className="row">
          {/* <button
            className="add"
            type="button"
            onClick={handleDowload}
          >
            <FontAwesomeIcon icon={faFileCsv} />
            {' '}
            {t('reports.download')}
          </button> */}
          {(context?.choiceBooklet === 1 || context.choiceBooklet === 2)
            && getBuildingsListQuery?.data?.data?.buildings.length > 1
            ? (
              <div className={styles.filter}>
                <label className={styles.filterLabel} htmlFor="buildings">
                  {t('inputs_historic.buildings')}
                </label>
                <Select
                  styles={customStyles}
                  id="buildings"
                  options={buildingsMapped}
                  placeholder={t('global.choose')}
                  noOptionsMessage={() => t('global.no_options_available')}
                  onChange={handleBuilding}
                  isMulti
                  loading={getBuildingsListQuery.isLoading}
                />
              </div>
            ) : null}
          <Toggle
            id="to-complete"
            label={t('inputs_historic.not_inputed')}
            checked={checkedd}
            value={completedSet}
            onChange={handleChange}
          />
        </div>
      </header>
      {
        getHistoricInputsListQuery.isLoading
        || getUsersListQuery.isLoading
        || getBuildingsListQuery.isLoading ? (
          <div className="loader" />
          ) : (
            <div>
              <div className={styles.table}>
                <Table
                  columns={columns}
                  isLoading={getHistoricInputsListQuery.isLoading}
                  data={getHistoricInputsListQuery?.data?.data?.inputs || []}
                  hasSort
                  hasPagination
                  pagination={(
                    <Pagination
                      previousLabel={(
                        <FontAwesomeIcon icon={faChevronLeft} />
                )}
                      nextLabel={(
                        <FontAwesomeIcon icon={faChevronRight} />
                )}
                      onPageChange={onPageChange}
                      totalCount={getHistoricInputsListQuery?.data?.data?.total || 0}
                      currentPage={page}
                      pageSize={10}
                    />
            )}
                />
              </div>
              <Modal
                isOpen={openModal}
                onRequestClose={() => setOpenModal(false)}
                style={customModalStyles}
              >
                <p className="modal_paragraph">{t('global.modal_content')}</p>
                <div className="modal_actions">
                  <button
                    type="button"
                    className="modal_button"
                    onClick={() => setOpenModal(false)}
                  >
                    {t('global.modal_dismiss')}
                  </button>
                  <button
                    type="button"
                    className="modal_button modal_button--success"
                    onClick={handleDelete}
                  >
                    {t('global.modal_validate')}
                  </button>
                </div>
              </Modal>
            </div>
          )
      }
      <footer className="footer">
        <div className="row mb-20">
          <Link to="/saisies" className="link">
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('inputs_historic.back_to_inputs')}</span>
          </Link>
        </div>
      </footer>
    </Layout>
  );
}

export default HistoricInputs;
