/* eslint-disable max-len */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-unused-vars */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import { sortBy } from 'lodash';
import { createVehicle, typeVehicleList } from '../../services/vehicules';
import { getBuildingsList } from '../../services/structures';
import useAppContext from '../../store/useAppContext';
import Input from '../../components/atoms/Input/Input';
import Button from '../../components/atoms/Button/Button';
import Checkbox from '../../components/atoms/Checkbox/Checkbox';
import Select from '../../components/atoms/Select/Select';
import Layout from '../../components/template/Layout';
import style from './AddVehicule.module.css';

function AddVehicule() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const context = useAppContext();
  const [buildingId, setBuildingId] = React.useState();

  const goBackUrl = (msg, vehicleId) => {
    navigate(`/ChecklistVehicule/${vehicleId}`);
    toast.success(msg);
  };

  const addVehicule = useMutation(createVehicle, {
    onSuccess: (data) => {
      goBackUrl(t('contract.succes_save_modif'), data.data.id);
    },
    onError: () => {
      toast.error(t('contract.erreur_save_modif'));
    },
  });

  // recup liste des types de vehicules
  const typeVehicleListMutate = useQuery(['typeVehicule'], () => typeVehicleList({
    page: 0,
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
  }));

  const initialValues = {
    licensePlate: '',
    buildingId,
    vehicleTypeId: '',
    brand: '',
    model: '',
    currentMileage: '',
    firstRegistrationDate: '',
    lastMaintenanceMileage: null,
    tireNumber: 4,
    lastControlDate: '',
    lastMaintenanceDate: '',
    serviceIntervalMonths: '',
    serviceIntervalMileage: '',
    hasWinterTires: false,
    hasTailgate: false,
    isActive: false,
    energy: '',
    critair: '',
    function: '',
    assignment: '',
    energyCard: '',
  };
  const validationSchema = Yup.object({
    licensePlate: Yup.string().max(10, t('addVehicule.licensePlate_max')).required(t('global.required_field')),
    buildingId: Yup.string(),
    vehicleTypeId: Yup.string().required(t('global.required_field')),
    brand: Yup.string().required(t('global.required_field')),
    model: Yup.string().required(t('global.required_field')),
    currentMileage: Yup.string().required(t('global.required_field')),
    firstRegistrationDate: Yup.string().required(t('global.required_field')),
    lastMaintenanceMileage: Yup.number().nullable(),
    tireNumber: Yup.number().required(t('global.required_field')),
    lastControlDate: Yup.string().test('lastControlDate', t('addVehicule.error_currentDate'), (value, testContext) => {
      if (testContext.parent.firstRegistrationDate >= value || value <= new Date()) {
        return false;
      }
      return true;
    }),
    lastMaintenanceDate: Yup.string().test('lastMaintenanceDate', t('addVehicule.error_currentDate'), (value, testContext) => {
      if (testContext.parent.firstRegistrationDate >= value || value <= new Date()) {
        return false;
      }
      return true;
    }),
    serviceIntervalMonths: Yup.number().integer().required(t('global.required_field')),
    serviceIntervalMileage: Yup.number().integer().required(t('global.required_field')),
    hasTailgate: Yup.bool(),
    hasWinterTires: Yup.bool(),
    isActive: Yup.bool(),
    energy: Yup.string().required(t('global.required_field')),
    critair: Yup.mixed().required(t('global.required_field')),
    function: Yup.string().required(t('global.required_field')),
    assignment: Yup.string(),
    energyCard: Yup.string().max(30, t('vehicule.energyCard_max')),
    rearAxle: Yup.string().when('tireNumber', {
      is: (val) => val === 4,
      then: Yup.string(),
    }),
    frontAxle: Yup.string().when('tireNumber', {
      is: (val) => val === 4,
      then: Yup.string(),
    }),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      addVehicule.mutate(values);
    },
  });

  // recup liste des batiments appartement au type vehicle list
  const getBuildingList = useQuery(['buildingList'], () => getBuildingsList({
    page: 1,
    establishmentId: context[0]?.choiceEstablishment?.id,
    bookletId: context[0]?.choiceBooklet,
  }), {
    onSuccess: (data) => {
      setBuildingId(data);
      formik.setFieldValue('buildingId', data?.data?.buildings[0]?.id);
      formik.setFieldValue('serviceIntervalMonths', 12);
      formik.setFieldValue('serviceIntervalMileage', 10000);
    },
  });

  const mapForSelect = (arrayToMap) => (arrayToMap?.length
    ? sortBy(arrayToMap, ['type']).map((item) => ({
      ...item,
      label: item.label,
      value: item.id,
    }))
    : []);

  const Energy = [
    { label: 'Diesel', value: 'diesel' },
    { label: 'Essence', value: 'essence' },
    { label: 'Éthanol', value: 'ethanol' },
    { label: 'Hybride', value: 'hybride' },
    { label: 'Électirque', value: 'electirque' },
    { label: 'Hydrogène', value: 'hydrogene' },
    { label: 'GPL', value: 'gpl' },
  ];

  const Critair = [
    { label: '0', value: 0 },
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5', value: 5 },
    { label: 'Non classés', value: 'unclassified' },
  ];

  const FuncVehicle = [
    { label: 'Véhicule de service', value: 'service' },
    { label: 'Véhicule de fonction', value: 'function' },
  ];

  const NombrePneu = [
    { label: '4', value: 4 },
    { label: '6', value: 6 },
    { label: '8', value: 8 },
    { label: '10', value: 10 },
  ];

  const mapForPneu = (arrayToMap) => (arrayToMap?.length
    ? sortBy(arrayToMap, ['type']).map((item) => ({
      ...item,
      label: item.label,
      value: item.value,
    }))
    : []);

  return (
    <Layout
      title={t('vehicule.add')}
      layout=""
      queryError={typeVehicleListMutate.isError || getBuildingList.isError}
    >
      <div>
        <header>
          <div className="row mb-20">
            <button
              type="button"
              className="link"
              onClick={() => navigate(-1)}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>{t('vehicule.back')}</span>
            </button>
          </div>
          <div className="row">
            <h1 className="title">{t('vehicule.add')}</h1>
          </div>
        </header>
      </div>
      <form onSubmit={formik.handleSubmit} className="form shadow-sm">
        <div className="form_group">
          {buildingId?.data?.data?.length > 1 && (
            <Input
              type="text"
              name="buildingId"
              id="buildingId"
              label={t('dairy.batiment')}
              placeholder={buildingId?.data?.buildings[0]?.name}
              value={buildingId?.data?.buildings[0]?.name}
              disabled
            />
          )}
          {formik.touched.buildingId && formik.errors.buildingId ? (
            <div className="error">{formik.errors.buildingId}</div>
          ) : null}
        </div>
        <div className="form_group">
          {' '}
        </div>
        <div className="form_group">
          <Input
            label={t('vehicule.licensePlate')}
            id="licensePlate"
            name="licensePlate"
            type="text"
            placeholder={t('vehicule.licensePlate')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.licensePlate}
            required
          />
          {formik.touched.licensePlate && formik.errors.licensePlate ? (
            <div className="error">{formik.errors.licensePlate}</div>
          ) : null}
        </div>
        <div className="form_group">
          <Select
            label={t('vehicule.type_vehicule')}
            id="vehicleTypeId"
            name="vehicleTypeId"
            type="select"
            className={style.vehicleTypeId}
            onChange={(value) => formik.setFieldValue('vehicleTypeId', value.value)}
            onBlur={formik.handleBlur}
            options={mapForSelect(typeVehicleListMutate?.data?.data?.vehicleTypes)}
            value={
              mapForSelect(typeVehicleListMutate).find(
                (item) => item.value === formik.values.vehicleTypeId,
              )
            }
            valueInput={formik.values.vehicleTypeId}
            required
            loading={typeVehicleListMutate.isLoading}
          />
          {formik.touched.vehicleTypeId && formik.errors.vehicleTypeId ? (
            <div className="error">{formik.errors.vehicleTypeId}</div>
          ) : null}
        </div>
        <div className="form_group">
          <Input
            label={t('vehicule.marque')}
            id="marque"
            name="brand"
            type="text"
            placeholder={t('vehicule.marque')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.brand}
            required
          />
          {formik.touched.brand && formik.errors.brand ? (
            <div className="error">{formik.errors.brand}</div>
          ) : null}
        </div>
        <div className="form_group">
          <Input
            label={t('vehicule.model')}
            id="model"
            name="model"
            type="text"
            placeholder={t('vehicule.model')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.model}
            required
          />
          {formik.touched.model && formik.errors.model ? (
            <div className="error">{formik.errors.model}</div>
          ) : null}
        </div>
        <div className="form_group">
          <Select
            label={t('addVehicule.type_carbu')}
            id="energy"
            name="energy"
            type="select"
            onChange={(value) => formik.setFieldValue('energy', value.value)}
            onBlur={formik.handleBlur}
            options={Energy}
            value={
              mapForSelect(Energy).find(
                (item) => item.value === formik.values.energy,
              )
            }
            valueInput={formik.values.energy}
            required
          />
          {formik.touched.energy && formik.errors.energy ? (
            <div className="error">{formik.errors.energy}</div>
          ) : null}
        </div>
        <div className="form_group">
          <Select
            label={t('addVehicule.vignette_critair')}
            id="critair"
            name="critair"
            type="select"
            onChange={(value) => formik.setFieldValue('critair', value.value)}
            onBlur={formik.handleBlur}
            options={Critair}
            value={
              mapForSelect(Critair).find(
                (item) => item.value === formik.values.critair,
              )
            }
            valueInput={formik.values.critair}
            required
          />
          {formik.touched.critair && formik.errors.critair ? (
            <div className="error">{formik.errors.critair}</div>
          ) : null}
        </div>
        <div className="form_group">
          <Select
            label={t('addVehicule.fonction_vehicule')}
            id="function"
            name="function"
            type="select"
            onChange={(value) => formik.setFieldValue('function', value.value)}
            onBlur={formik.handleBlur}
            options={FuncVehicle}
            value={
              mapForSelect(FuncVehicle).find(
                (item) => item.value === formik.values.function,
              )
            }
            valueInput={formik.values.function}
            required
          />
          {formik.touched.function && formik.errors.function ? (
            <div className="error">{formik.errors.function}</div>
          ) : null}
        </div>
        <div className="form_group">
          <Input
            label={t('addVehicule.affectation_vehicule')}
            id="assignment"
            name="assignment"
            type="text"
            placeholder={t('addVehicule.affectation_vehicule')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.assignment}
          />
          {formik.touched.assignment && formik.errors.assignment ? (
            <div className="error">{formik.errors.assignment}</div>
          ) : null}
        </div>
        <div className="form_group">
          <Input
            label={t('vehicule.date_premiere_mise_circulation')}
            id="firstRegistrationDate"
            name="firstRegistrationDate"
            type="date"
            max={(new Date()).toISOString().split('T')[0]}
            placeholder={t('vehicule.date_premiere_mise_circulation')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.firstRegistrationDate}
            required
          />
          {formik.touched.firstRegistrationDate && formik.errors.firstRegistrationDate ? (
            <div className="error">{formik.errors.firstRegistrationDate}</div>
          ) : null}
        </div>
        <div className="form_group">
          <Input
            label={t('addVehicule.first_controle')}
            id="lastControlDate"
            name="lastControlDate"
            type="date"
            max={(new Date()).toISOString().split('T')[0]}
            placeholder={t('addVehicule.first_controle')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.lastControlDate}
          />
          {formik.touched.lastControlDate && formik.errors.lastControlDate ? (
            <div className="error">{formik.errors.lastControlDate}</div>
          ) : null}
        </div>
        <div className="form_group">
          <Input
            label={t('vehicule.kilometrageactuel')}
            id="currentMileage"
            name="currentMileage"
            type="number"
            min={0}
            placeholder={t('vehicule.kilometrageactuel')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.currentMileage}
            required
          />
          {formik.touched.currentMileage && formik.errors.currentMileage ? (
            <div className="error">{formik.errors.currentMileage}</div>
          ) : null}
        </div>
        <div className="form_group">
          <Select
            label={t('vehicule.nmb_pneu')}
            id="nmb_pneu"
            name="tireNumber"
            type="select"
            onChange={(value) => formik.setFieldValue('tireNumber', value.value)}
            onBlur={formik.handleBlur}
            options={mapForPneu(NombrePneu)}
            value={
              mapForPneu(NombrePneu).find(
                (item) => item.value === formik.values.tireNumber,
              )
            }
          />
          {!formik.values.tireNumber && (
            <input
              tabIndex="-1"
              autoComplete="off"
              style={{ opacity: 0, height: 0, width: 0 }}
              required
              value={formik.values.tireNumber}
            />
          )}
          {formik.touched.tireNumber && formik.errors.tireNumber ? (
            <div className="error">{formik.errors.tireNumber}</div>
          ) : null}
        </div>
        {formik.values.tireNumber !== 4 ? null : (
          <>
            <div className="form_group">
              <Input
                label={t('vehicule.rearAxle')}
                id="rearAxle"
                name="rearAxle"
                type="text"
                placeholder={t('vehicule.rearAxlesExemple')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.rearAxle}
              />
              {formik.touched.rearAxle && formik.errors.rearAxle ? (
                <div className="error">{formik.errors.rearAxle}</div>
              ) : null}
            </div>
            <div className="form_group">
              <Input
                label={t('vehicule.frontAxle')}
                id="frontAxle"
                name="frontAxle"
                type="text"
                placeholder={t('vehicule.frontAxlesExemple')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.frontAxle}
              />
              {formik.touched.frontAxle && formik.errors.frontAxle ? (
                <div className="error">{formik.errors.frontAxle}</div>
              ) : null}
            </div>
          </>
        )}
        <div className="form_group">
          <Input
            label={t('vehicule.kilometrage_dernier_entretien')}
            id="lastMaintenanceMileage"
            name="lastMaintenanceMileage"
            type="number"
            step={1}
            min={0}
            placeholder={t('vehicule.kilometrage_dernier_entretien')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.lastMaintenanceMileage || null}
          />
          {formik.touched.lastMaintenanceMileage && formik.errors.lastMaintenanceMileage ? (
            <div className="error">{formik.errors.lastMaintenanceMileage}</div>
          ) : null}
        </div>
        <div className="form_group">
          <Input
            label={t('vehicule.date_dernire_revision')}
            id="lastMaintenanceDate"
            name="lastMaintenanceDate"
            type="date"
            min={formik.values.firstRegistrationDate}
            placeholder={t('vehicule.date_dernire_revision')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.lastMaintenanceDate}
          />
          {formik.touched.lastMaintenanceDate && formik.errors.lastMaintenanceDate ? (
            <div className="error">{formik.errors.lastMaintenanceDate}</div>
          ) : null}
        </div>
        <div className="form_group">
          <Input
            label={t('vehicule.periodicite__km_revision')}
            id="serviceIntervalMileage"
            name="serviceIntervalMileage"
            type="number"
            placeholder={t('vehicule.periodicite__km_revision')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.serviceIntervalMileage}
            min={10000}
            required
          />
          {/* {formik.values.serviceIntervalMileage === '' ? (
            formik.setFieldValue('serviceIntervalMileage', 10000)
          ) : null} */}
          {formik.touched.serviceIntervalMileage && formik.errors.serviceIntervalMileage ? (
            <div className="error">{formik.errors.serviceIntervalMileage}</div>
          ) : null}
        </div>
        <div className="form_group">
          <Input
            label={t('vehicule.periodicite__mois_revision')}
            id="serviceIntervalMonths"
            name="serviceIntervalMonths"
            type="number"
            placeholder={t('vehicule.periodicite__mois_revision')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.serviceIntervalMonths}
            min={12}
            required
          />
          {/* {formik.values.serviceIntervalMonths === '' ? (
            formik.setFieldValue('serviceIntervalMonths', 12)
          ) : null} */}
          {formik.touched.serviceIntervalMonths && formik.errors.serviceIntervalMonths ? (
            <div className="error">{formik.errors.serviceIntervalMonths}</div>
          ) : null}
        </div>
        <div className="form_group">
          <Input
            label={t('vehicule.energyCard')}
            id="energyCard"
            name="energyCard"
            type="text"
            placeholder={t('vehicule.energyCard')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.energyCard}
          />
          {formik.touched.energyCard && formik.errors.energyCard ? (
            <div className="error">{formik.errors.energyCard}</div>
          ) : null}
        </div>
        <div className="form_group">
          {' '}
        </div>
        <div className="form_group">
          <Checkbox
            label={t('vehicule.hasWinterTires')}
            id="hasWinterTires"
            name="hasWinterTires"
            type="checkbox"
            onChange={(e) => { formik.setFieldValue('hasWinterTires', e.target.checked ? true : false); }}
            onBlur={formik.handleBlur}
            checked={formik.values.hasWinterTires === true}
            value={formik.values.hasWinterTires ? true : false}
          />
          {formik.touched.hasWinterTires && formik.errors.hasWinterTires ? (
            <div className="error">{formik.errors.hasWinterTires}</div>
          ) : null}
        </div>
        <div className="form_group">
          {' '}
        </div>
        <div className="form_group">
          <Checkbox
            label={t('vehicule.hasTailgate')}
            id="hasTailgate"
            name="hasTailgate"
            type="checkbox"
            onChange={(e) => { formik.setFieldValue('hasTailgate', e.target.checked ? true : false); }}
            onBlur={formik.handleBlur}
            checked={formik.values.hasTailgate === true}
            value={formik.values.hasTailgate ? true : false}
          />
          {formik.touched.hasTailgate && formik.errors.hasTailgate ? (
            <div className="error">{formik.errors.hasTailgate}</div>
          ) : null}
        </div>
        <div className="form_group">
          {' '}
        </div>
        <section className="form_footer">
          <div className="form_infos">
            <small>{t('add_structure.mandatory_fields')}</small>
          </div>
          <Button
            type="submit"
            className="form_submit"
            label={t('vehicule.add')}
          />
        </section>
      </form>
      <footer className="footer">
        <button type="button" className="link" onClick={() => navigate(-1)}>
          <FontAwesomeIcon icon={faChevronLeft} />
          <span>{t('vehicule.back')}</span>
        </button>
      </footer>
    </Layout>
  );
}

export default AddVehicule;
