import { useQuery } from 'react-query';
import React from 'react';
import {
  faEdit, faChevronLeft, faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getVacationList } from '../../services/vacation';
import useTableFiltersPagination from '../../hooks/useTableFiltersPagination';
import Button from '../../components/atoms/Button/Button';
import Layout from '../../components/template/Layout';
import Pagination from '../../components/molecules/Pagination';
import useAppContext from '../../store/useAppContext';
import styles from './Vacantion.module.css';
import Table from '../../components/molecules/Table/Table';
import cn from '../../utils/cn';

function Vacation() {
  const [context] = useAppContext();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { page, onPageChange } = useTableFiltersPagination({ initialFilters: {} });

  const getVacationListQuery = useQuery(['vacationLit'], () => getVacationList({
    id: context?.choiceEstablishment?.id,
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
  }));
  const buttonedit = (id) => (
    <Button
      type="button"
      className={styles.edit}
      label={<FontAwesomeIcon icon={faEdit} />}
      onClick={() => {
        navigate(`/vacation/edit/${id}`);
      }}
    />
  );

  const collum = React.useMemo(
    () => [
      {
        Header: t('vacantion.bulding'),
        accessor: 'buildingName',
      },
      {
        Header: t('vacantion.carnet'),
        accessor: 'booklet',
        Cell: ({ row }) => {
          if (row?.original?.booklet || row?.original?.booklet !== null) {
            return t(`buildings.${row.original.booklet}`);
          }
          return '-';
        },
      },
      {
        Header: t('vacantion.start_date'),
        accessor: 'startDate',
        Cell: ({ row }) => {
          if (row.original.startDate === null) {
            return '-';
          }
          return new Date(row.original.startDate).toLocaleDateString();
        },
      },
      {
        Header: t('vacantion.end_date'),
        accessor: 'endDate',
        Cell: ({ row }) => {
          if (row.original.endDate === null) {
            return '-';
          }
          return new Date(row.original.endDate).toLocaleDateString();
        },
      },
      {
        Header: '',
        accessor: 'action',
        Cell: ({ row }) => buttonedit(row.original.buildingId),
      },
    ],
    [],
  );

  const navigateDashboards = () => {
    const establishment = context?.choiceEstablishment;
    let estab = establishment?.name?.replace(/ /g, '-');
    estab = estab?.replace('/', '');
    estab = estab?.replace(/-+/g, '-');
    if (context.choiceBooklet === 1) {
      navigate(`/sanitaire/${establishment.id}-${estab}/dashboards`);
    }
    if (context.choiceBooklet === 2) {
      navigate(`/securite/${establishment.id}-${estab}/dashboards`);
    }
    if (context.choiceBooklet === 3) {
      navigate(`/vehicule/${establishment.id}-${estab}/dashboards`);
    }
  };

  return (
    <Layout
      title="Vacation"
      layout="table"
      queryError={
        getVacationListQuery?.error
      }
    >
      <header className="header">
        <div className="row mb-20">
          <button type="button" className="link" onClick={() => navigateDashboards()}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('global.dashboard')}</span>
          </button>
        </div>
        <div className={cn(['row', styles.row])}>
          <h1 className="title">{t('vacantion.title')}</h1>
        </div>
      </header>
      <div className={styles.table}>
        <Table
          columns={collum}
          isLoading={getVacationListQuery.isLoading}
          data={getVacationListQuery?.data?.data?.buildingVacations || []}
          hasSort
          pagination={(
            <Pagination
              previousLabel={(
                <FontAwesomeIcon icon={faChevronLeft} />
              )}
              nextLabel={(
                <FontAwesomeIcon icon={faChevronRight} />
              )}
              onPageChange={onPageChange}
              totalCount={getVacationListQuery?.data?.data?.total || 0}
              currentPage={page}
              pageSize={10}
              numberElementsDisplayed={getVacationListQuery?.data?.data?.buildingVacations.length || 0}
            />
          )}
        />
      </div>
      <footer>
        <div className="row mt-20">
          <button type="button" className="link" onClick={() => navigateDashboards()}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('global.dashboard')}</span>
          </button>
        </div>
      </footer>
    </Layout>
  );
}

export default Vacation;
