import apiCron from './_apiCron';

/**
 * GET - Cron sanitary user
* @param {string|null} optionalParam - Paramètre facultatif
 * @returns {Promise<AxiosResponse>}
 */
export const sendEmailSanitaryUser = (optionalParam = null) => {
  const url = !Array.isArray(optionalParam) ? `/EmailSanitaryUser/${optionalParam}` : '/EmailSanitaryUser';
  return apiCron.get(url);
};

/**
 * GET - Cron security user
 * @returns {Promise<AxiosResponse>}
 */
export const sendEmailSecurityUser = (optionalParam = null) => {
  const url = !Array.isArray(optionalParam) ? `/EmailSecurityUser/${optionalParam}` : '/EmailSecurityUser';
  return apiCron.get(url);
};

/**
 * GET - Cron securite admin
* @param {string|null} optionalParam - Paramètre facultatif
 * @returns {Promise<AxiosResponse>}
 */
export const sendEmailSecurityAdmin = (optionalParam = null) => {
  const url = !Array.isArray(optionalParam) ? `/EmailSecurityAdmin/${optionalParam}` : '/EmailSecurityAdmin';
  return apiCron.get(url);
};

/**
 * GET - Cron sanitaire admin
* @param {string|null} optionalParam - Paramètre facultatif
 * @returns {Promise<AxiosResponse>}
 */
export const sendEmailSanitaryAdmin = (optionalParam = null) => {
  const url = !Array.isArray(optionalParam) ? `/EmailSanitaryAdmin/${optionalParam}` : '/EmailSanitaryAdmin';
  return apiCron.get(url);
};

/**
 * GET - recap report
 * @param {string|null} optionalParam - Paramètre facultatif
 * @returns {Promise<AxiosResponse>}
 */
export const sendRecapReports = (optionalParam = null) => {
  const url = !Array.isArray(optionalParam) ? `/EmailRecapReport/${optionalParam}` : '/EmailRecapReport';
  return apiCron.get(url);
};
