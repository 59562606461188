/* eslint-disable no-nested-ternary */
/* eslint-disable no-else-return */
/* eslint-disable max-len */
/* eslint-disable prefer-template */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  faChevronLeft,
  faFileArrowDown,
  faCircle,
  faFilePdf,
} from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import { useQuery, useMutation } from 'react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import Button from '../../components/atoms/Button/Button';
import Search from '../../components/molecules/Search/Search';
import Layout from '../../components/template/Layout';
import Table from '../../components/molecules/Table/Table';
import styles from './monCarnet.module.css';
import cn from '../../utils/cn';
import useAppContext from '../../store/useAppContext';
import { deleteAnalyse, getAnalysesLastList, downloadAnalyse } from '../../services/analyses';
import { getInputsToComplete } from '../../services/inputs';
import Temperature from './Temperature';
import ColdTemperature from './ColdTemperature';
import Meter from './Meter';
import Permissions from '../../components/molecules/Permissions/Permissions';
import { getTemperatureHot, getTemperatureCold, getMeter } from '../../services/monCarnet';
import { getMyBookletList } from '../../services/registre';
import AnalyseList from '../../components/template/AnalyseList/AnalyseList';

function MonCarnet() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [search, setSearch] = useState('');
  const [context] = useAppContext();
  const [buildingId, setBuildingId] = useState(undefined);

  const customModalStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '2rem',
    },
  };

  const getInputsToCompleteQuery = useQuery(['inputs'], () => getInputsToComplete({
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
  }), {
    onSuccess: (response) => {
      if (buildingId === undefined) {
        setBuildingId(response?.data?.buildingInputInfos[0]?.buildingId);
      }
    },
  });

  const TemperatureColdQuery = useQuery(['temperatureCold', buildingId], () => getTemperatureCold({
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
    buildingId,
  }), {
    enabled: buildingId !== undefined,
  });

  const TemperatureHotQuery = useQuery(['temperatureHot', buildingId], () => getTemperatureHot({
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
    buildingId,
  }), {
    enabled: buildingId !== undefined,
  });

  const MeterQuery = useQuery(['meter', buildingId], () => getMeter({
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
    buildingId,
  }), {
    enabled: buildingId !== undefined,
  });

  const AnalysisList = useQuery(
    ['analyses', search],
    () => getAnalysesLastList({
      page: 0,
      search,
      bookletId: context?.choiceBooklet,
      establishmentId: context?.choiceEstablishment?.id,
    }),
  );

  const deleteAnalysMutate = useMutation(deleteAnalyse, {
    onSuccess: () => {
      toast.success(t('analyses.delete_success'));
      setOpenModal(false);
      AnalysisList.refetch();
    },
  });

  const handleBuilding = (id) => {
    setBuildingId(id);
  };
  const downloadAnalysMutate = useMutation(downloadAnalyse, {});

  const handleDelete = () => {
    setOpenModal(false);
    deleteAnalysMutate.mutate(false);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value.toLowerCase());
  };

  const handleDownloadPdfExport = () => {
    const establishmentId = context?.choiceEstablishment?.id;
    // const token = buildings.find((data) => data.id === buildingId);
    const token = getInputsToCompleteQuery?.data?.data?.buildingInputInfos.find((data) => data.buildingId === buildingId).token;
    // Open the export URL in a new tab
    window.open(`${process.env.REACT_APP_API_URL}_exportSanitary/${establishmentId}/${buildingId}/${token}`, '_blank');
  };

  const getMyBookletListQuery = useQuery(['RegistreList', buildingId], () => getMyBookletList({
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
    buildingId,
  }), {
    enabled: buildingId !== undefined,
  });
  const docfile = (id, fileName, type) => {
    if (type === 'report') {
      window.open(`${process.env.REACT_APP_API_URL}reports/download-report/${id}/${fileName}`, '_blank');
    } else if (type === 'document') {
      window.open(`${process.env.REACT_APP_API_URL}document-base-items/download-document/${id}/${fileName}`, '_blank');
    } else if (type === 'training') {
      window.open(`${process.env.REACT_APP_API_URL}trainings/download-document/${id}/${fileName}`, '_blank');
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: t('registre.maintenance'),
        accessor: 'interventionTitle',
      },
      {
        Header: t('registre.interventionDate'),
        accessor: 'report.reportDate',
        Cell: ({ row }) => {
          if (row?.original?.report?.reportDate === null) {
            return '-';
          }
          return new Date(row?.original?.report?.reportDate).toLocaleDateString();
        },
      },
      {
        Header: t('registre.NbrRepport'),
        accessor: 'report.reference',
        Cell: ({ row }) => {
          if (row?.original?.report?.reference !== null) {
            return row?.original?.report?.reference;
          }
          return '-';
        },
      },
      {
        Header: `${t('registre.report')}`,
        accessor: 'report.reportFileName',
        Cell: ({ row }) => (
          <div>
            {Permissions({ permission: 'REPORT_READ' }) !== undefined
              || Permissions({ permission: 'BOOKLET_READ' }) !== undefined
              ? (
                <Button
                  label={(
                    <FontAwesomeIcon icon={faFileArrowDown} transform="grow-6" />
                  )}
                  type="button"
                  className="action edit"
                  title={t('reports.view')}
                  onClick={() => docfile(row?.original?.report?.id, row?.original?.report?.reportFileName, 'report')}
                />
              ) : null }
          </div>
        ),
      },
      {
        Header: t('registre.levées'),
        accessor: 'liftedObservationsCount',
        Cell: ({ row }) => (
          <div>
            {Permissions({ permission: 'OBSERVATION_LIST' }) !== undefined
              || Permissions({ permission: 'BOOKLET_READ' }) !== undefined
              ? (
                <Button
                  label={(
                    <div className="fa-layers fa-fw" style={{ color: 'white' }}>
                      <FontAwesomeIcon icon={faCircle} style={{ color: '#158974' }} transform="grow-15" />
                      <span
                        className={cn(['fa-layers-text fa-inverses', styles.strong])}
                        data-fa-transform="shrink-11 left-20 up-2"
                      >
                        {row?.original?.liftedObservationsCount}
                      </span>
                    </div>
                  )}
                  type="button"
                  className="action edit"
                  title={t('reports.obslevee')}
                  onClick={() => navigate(`/Observations/lifted/${row?.original?.report?.id}`)}
                >
                  {' '}
                </Button>
              ) : null }
          </div>
        ),
      },
      {
        Header: t('registre.restantes'),
        accessor: 'unliftedObservationsCount',
        Cell: ({ row }) => (
          <div>
            {Permissions({ permission: 'OBSERVATION_LIST' }) !== undefined
              || Permissions({ permission: 'BOOKLET_READ' }) !== undefined
              ? (
                <Button
                  label={(
                    <div className="fa-layers fa-fw" style={{ color: 'white' }}>
                      <FontAwesomeIcon icon={faCircle} style={{ color: '#e74c3c' }} transform="grow-15" />
                      <span
                        className={cn(['fa-layers-text fa-inverses', styles.strong])}
                        data-fa-transform="shrink-11 left-20 up-2"
                      >
                        {row?.original?.unliftedObservationsCount}
                      </span>
                    </div>
                  )}
                  type="button"
                  className="action edit"
                  title={t('reports.obsrestante')}
                  onClick={() => {
                    if (window.innerWidth < 768) {
                      navigate(`/Observations/remaining/${row?.original?.report?.id}`);
                    } else {
                      navigate(`/Observations/remaining/${row?.original?.report?.id}`);
                    }
                  }}
                >
                  {' '}
                </Button>
              ) : null }
          </div>
        ),
      },
    ],
    [],
  );

  const navigateDashboards = () => {
    const establishment = context?.choiceEstablishment;
    let estab = establishment?.name?.replace(/ /g, '-');
    estab = estab?.replace('/', '');
    estab = estab?.replace(/-+/g, '-');
    if (context.choiceBooklet === 1) {
      navigate(`/sanitaire/${establishment.id}-${estab}/dashboards`);
    }
    if (context.choiceBooklet === 2) {
      navigate(`/securite/${establishment.id}-${estab}/dashboards`);
    }
    if (context.choiceBooklet === 3) {
      navigate(`/vehicule/${establishment.id}-${estab}/dashboards`);
    }
  };

  return (
    <>
      <Layout
        title={t('carnet.title')}
        layout="table"
        queryError={
          AnalysisList.error
          || downloadAnalysMutate?.error
          || getInputsToCompleteQuery?.error
          || TemperatureColdQuery?.error
          || TemperatureHotQuery?.error
          || MeterQuery?.error
        }
      >
        <header className={styles.headers}>
          <div className={cn([styles.mb20, styles.rows])}>
            <button type="button" className={styles.links} onClick={() => navigateDashboards()}>
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>{t('global.dashboard')}</span>
            </button>
          </div>
          <div className={styles.rows}>
            <h1 className="title">
              {t('carnet.title')}
            </h1>
          </div>
          <div className={styles.rows}>
            {context?.choiceBooklet === 1 && (
              <button
                className={styles.adds}
                type="button"
                onClick={handleDownloadPdfExport}
              >
                <FontAwesomeIcon icon={faFilePdf} />
                {' '}
                {t('global.download_sanitary_export')}
              </button>
            )}
            <Search className={styles.mb20} onChange={handleSearch} />
          </div>
          <div className={styles.filtersToComplete}>
            {getInputsToCompleteQuery?.data?.data?.buildingInputInfos.map((building) => (
              <Button
                key={building.buildingId}
                type="button"
                label={building.buildingName}
                className={cn([styles.filter, buildingId === building.buildingId
                  ? styles.active
                  : '', 'shadow-md'])}
                onClick={() => handleBuilding(building.buildingId)}
              />
            ))}
          </div>
        </header>
        {getInputsToCompleteQuery.isLoading ? (
          <div className="loader" />
        ) : (
          <>
            {TemperatureHotQuery?.data?.data?.temps?.length > 0 || TemperatureColdQuery?.data?.data?.temps?.length > 0
              ? (
                <div className={cn(['rows', styles.block])} id="temperature">
                  <h3 className={styles.titles}>{t('carnet.temperature')}</h3>
                  {TemperatureHotQuery?.data?.data?.temps?.length > 0
                    ? (
                      <div className={styles.tables}>
                        <h4 className={styles.title}>{t('carnet.hot_temperature')}</h4>
                        <Temperature
                          data={TemperatureHotQuery}
                        />
                      </div>
                    ) : null}
                  {TemperatureColdQuery?.data?.data?.temps?.length > 0
                    ? (
                      <div className={styles.tables}>
                        <h4 className={styles.title}>{t('carnet.cold_temperature')}</h4>
                        <ColdTemperature
                          data={TemperatureColdQuery}
                        />
                      </div>
                    ) : null}
                </div>
              ) : null}
            {MeterQuery?.data?.data?.meters?.length > 0
              ? (
                <div className={cn(['rows', styles.block])}>
                  <h3 className={styles.titles}>{t('carnet.meter')}</h3>
                  <div className={styles.tables}>
                    <Meter
                      data={MeterQuery}
                    />
                  </div>
                </div>
              ) : null}
            <div className={cn(['rows', styles.block])}>
              <h3 className={styles.titles}>{t('carnet.maintenance')}</h3>
              <div className={styles.tables}>
                <Table
                  columns={columns}
                  isLoading={getMyBookletListQuery.isLoading}
                  data={getMyBookletListQuery?.data?.data?.qualifiedTechnician || []}
                  hasSort
                />
              </div>
            </div>
            <div className={styles.content}>
              <AnalyseList
                AnalysisList={AnalysisList}
              />
            </div>
          </>
        )}
      </Layout>
      <Modal
        isOpen={openModal}
        onRequestClose={() => setOpenModal(false)}
        style={customModalStyles}
      >
        <p className="modal_paragraph">
          {t('global.modal_content')}
        </p>
        <div className="modal_actions">
          <Button
            type="button"
            className="modal_button"
            label={t('global.modal_dismiss')}
            onClick={() => setOpenModal(false)}
          />
          <Button
            type="button"
            className="modal_button modal_button--success"
            label={t('global.modal_validate')}
            onClick={handleDelete}
          />
        </div>
      </Modal>
    </>
  );
}
export default MonCarnet;
